import React, { lazy } from 'react';

// eslint-disable-next-line no-unused-vars
import { Route, Redirect } from 'react-router-dom';

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import ROUTES_PATH from './RoutesPath';
import { useAuth } from '../context/AuthContext';

const Register = lazy(() => import('../pages/Register'));
const ValidateAccount = lazy(() => import('../pages/ValidateAccount'));
const PasswordReset = lazy(() => import('../pages/PasswordReset'));
const RecoverPassword = lazy(() => import('../pages/RecoverPassword'));
const Login = lazy(() => import('../pages/Login'));
const Configuration = lazy(() => import('../pages/Configuration'));
const Logout = lazy(() => import('../pages/Logout'));
const ResendEmailConfirmation = lazy(() =>
  import('../pages/ResendEmailConfirmation')
);
const ChooseAccount = lazy(() => import('../pages/ChooseAccount'));
const EnterPin = lazy(() => import('../pages/EnterPin'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const PacksPage = lazy(() => import('../pages/PacksPage'));
const Cart = lazy(() => import('../pages/Cart'));
const ResultPayment = lazy(() => import('../pages/ResultPayment'));
const Family = lazy(() => import('../pages/Family'));
const DetailVideo = lazy(() => import('../pages/DetailVideo'));
const Information = lazy(() => import('../pages/Information'));

const Welcome = lazy(() => import('../pages/Welcome'));
const Workshops = lazy(() => import('../pages/Workshops'));
const DetailWorkshop = lazy(() => import('../pages/DetailWorkshop'));
const DetailPack = lazy(() => import('../components/DetailPack'));
const Blog = lazy(() => import('../pages/Blog'));
const DetailBlog = lazy(() => import('../pages/DetailBlog'));
const MyWorkshops = lazy(() => import('../pages/MyWorkshops'));
const TalleresPadre = lazy(() => import('../pages/TalleresPadre'));

export const RouteWrapper = ({
  requireTutor,
  requireLogin,
  redirectIfLogged,
  hasFacebookPixel,
  redirectUserNotSelected,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const { isUserLogged, isTutor, isUserSelected } = useAuth();

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (hasFacebookPixel) {
      ReactPixel.pageView();
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pageUrl: window.location.pathname + window.location.search,
      },
    });
  }, []);

  if (redirectUserNotSelected && !isUserSelected() && isUserLogged()) {
    return (
      <Redirect
        to={{
          pathname: ROUTES_PATH.CHOOSE_ACCOUNT,
          state: {
            prevPathname: props.path,
          },
        }}
      />
    );
  }
  if (redirectIfLogged && isUserLogged()) {
    return <Redirect to="/" />;
  }

  if (requireLogin && !isUserLogged()) {
    return <Redirect to={ROUTES_PATH.LOGIN} />;
  }

  if (requireTutor && !isTutor()) {
    return <Redirect to={ROUTES_PATH.LOGIN} />;
  }

  return <Route {...props} />;
};

const routes = [
  {
    path: ROUTES_PATH.LOGIN,
    component: Login,
    redirectIfLogged: true,
    redirectUserNotSelected: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.REGISTER,
    component: Register,
    redirectIfLogged: true,
    redirectUserNotSelected: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.CONFIRMATION_EMAIL,
    component: ValidateAccount,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.PASSWORD_RESET,
    component: PasswordReset,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.RECOVER_PASSWORD,
    component: RecoverPassword,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.RESEND_EMAIL_CONFIRMATION,
    component: ResendEmailConfirmation,
    redirectUserNotSelected: true,
    redirectIfLogged: true,
  },
  {
    path: ROUTES_PATH.CONFIGURATION,
    component: Configuration,
    requireLogin: true,
    requireTutor: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.LOGOUT,
    component: Logout,
    requireLogin: true,
  },
  {
    path: ROUTES_PATH.CHOOSE_ACCOUNT,
    component: ChooseAccount,
    requireLogin: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.ENTER_PIN,
    component: EnterPin,
    requireLogin: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.WORKSHOP_CATEGORY(),
    component: Workshops,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.WORKSHOPS,
    component: TalleresPadre,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.DETAIL_PACK(),
    component: DetailPack,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.PACKS_QUANTITY(),
    component: PacksPage,
    hasFacebookPixel: true,
  },

  {
    path: ROUTES_PATH.DETAIL_VIDEO_WORKSHOP(),
    component: DetailVideo,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.DETAIL_WORKSHOP(),
    component: DetailWorkshop,
    hasFacebookPixel: true,
  },

  {
    path: ROUTES_PATH.DETAIL_BLOG(),
    component: DetailBlog,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.BLOG,
    component: Blog,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.MY_WORKSHOPS,
    component: MyWorkshops,
    redirectUserNotSelected: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.MY_PROFILE,
    component: MyProfile,
    redirectUserNotSelected: true,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.CART,
    component: Cart,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.RESULT_PAYMENT,
    component: ResultPayment,
    hasFacebookPixel: true,
  },
  {
    path: ROUTES_PATH.FAMILY,
    component: Family,
    hasFacebookPixel: true,
    redirectUserNotSelected: true,
  },
  {
    path: ROUTES_PATH.INFORMATION,
    component: Information,
    hasFacebookPixel: true,
  },
  {
    path: '/',
    component: Welcome,
    hasFacebookPixel: true,
  },
];

export default routes;
